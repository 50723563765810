<template>
  <CampaignForm :loading="loading" :errors="errors" @submit="onSubmit" />
</template>

<script>
import { CREATE_CAMPAIGN } from "./graphql/mutations";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import CampaignForm from "./components/CampaignForm/CampaignForm.vue";
import { GET_CAMPAIGN_LIST } from "./graphql/queries";
import { mutationUpdate } from "@/utils/graphql/";

export default {
  name: "NewCampaignView",
  components: { CampaignForm },
  mixins: [hasSnackbarAccess],
  props: {
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    errors: [],
  }),
  methods: {
    async onSubmit({ values, resolve }) {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_CAMPAIGN,
        // We must not send id when creating campaign
        variables: { input: { ...values, id: undefined } },
        update: mutationUpdate({
          query: GET_CAMPAIGN_LIST,
          queryVariables: {},
          mutationProp: "bouncebackCampaignCreate",
          mutationObjectProp: "bouncebackCampaign",
          queryListProp: "bouncebackCampaigns",
        }),
      });

      const { bouncebackCampaign, errors } = data.bouncebackCampaignCreate;

      if (errors.length > 0) {
        this.setErrors(errors);
      } else {
        this.showSnackbar({
          text: this.$t("bounceback.setup.newCamapign.createCampaignSuccess", {
            campaignName: bouncebackCampaign.name,
          }),
          color: "success",
        });

        this.setErrors([]);

        this.$router.replace({
          name: this.raiLinkTo.editCampaign,
          params: { campaignId: bouncebackCampaign.id },
        });
      }

      resolve();
    },
    setLoading(loading) {
      this.loading = loading;
    },
    setErrors(errors) {
      this.errors = errors;
    },
  },
};
</script>
